<template>
  <a-modal
    class="doc-edit-modal"
    title="Редактировать"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <a-form :model="doc">
      <div class="col-md-12">
        <a-form-item label="Название страницы">
          <a-input
            ref="docName"
            placeholder="Введите название"
            v-model:value="doc.title"
          />
        </a-form-item>
        <a-form-item label="Короткая ссылка">
          <a-input
            placeholder="Введите ссылку"
            v-model:value="doc.slug"
          />
        </a-form-item>
        <a-form-item label="Иконка">
          <a-input
            placeholder="Введите название иконки"
            v-model:value="doc.logo"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, onMounted, nextTick} from 'vue';
import apiClient from "@/services/axios";

const props = defineProps({
  docId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let loading = ref(true),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  docName = ref("docName"),
  doc = reactive({
    title: '',
    slug: '',
    logo: '',
  });

const
  resetForm = () => {
    Object.assign(doc, {
      title: '',
      slug: '',
      logo: '',
    });
    modalVisible.value = false;
    emit('hide-modal')
  },
  update = () => {
    confirmLoading.value = true;

    let model = toRaw(doc)

    return apiClient
      .put(`/integrations/update?id=${props.docId}`, model)
      .then(response => {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      }).catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        emit('hide-modal')
        // console.log(err)
      }).finally(() => {
        confirmLoading.value = false;
        modalVisible.value = false;
        emit('reload')
        emit('hide-modal')
      })
  };

apiClient.get(`/integrations/view?id=${props.docId}`)
  .then(response => {
    if (response) {
      Object.assign(doc, {
        title: response.data.data.title,
        slug: response.data.data.slug,
        logo: response.data.data.logo,
      })
    }
  }).catch(() => {
    // console.log(err)
    loading.value = false
  })
  .finally(() => {
    loading.value = false
  })

onMounted(() => nextTick(() => docName.value?.focus()))
</script>

<style scoped>
@import "./edit.css";
</style>
