<template>
  <div>
    <div class="row">
      <div class="col-md-12 text-right">
        <a-typography-link href="https://feathericons.com/" target="_blank">Список иконок</a-typography-link>&nbsp;&nbsp;<vb-order-doc @reload="reload()" :tree="treeData" />&nbsp;&nbsp;<vb-create-doc @reload="reload()" />
      </div>
    </div>
    <vb-edit-doc
      v-if="editModal"
      :doc-id="docId"
      @reload="reload()"
      @hide-modal="hideEditModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #logo="{ record }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="getIcon(record.logo)"></span>&nbsp;<span>{{ record.logo }}</span>
        <!--eslint-enable-->
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="text-right">
          <a-tooltip placement="top" class="mx-2">
            <template #title>Контент</template>
            <a @click="router.push({ name: 'integration-sections', params: { page: record.id } })"><i class="fe fe-file"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Редактировать</template>
            <a @click="showEditModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import { watch, reactive, ref, toRefs, onMounted } from 'vue'
import VbCreateDoc from "@/views/integration/create"
import VbOrderDoc from "@/views/integration/order"
import { useRoute, useRouter } from 'vue-router'
import VbEditDoc from "@/views/integration/edit"
import { message, Modal } from 'ant-design-vue'
import apiClient from "@/services/axios"

let
  dataSource = ref([]),
  loading = ref(false),
  editModal = ref(false),
  total = ref(0),
  docId = ref(0);

const router = useRouter(),
  route = useRoute(),
  treeData = ref([]),
  initialParams = {
    page: 1,
    'per-page': 50,
    sort: '',
    title: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Номер',
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      width: 100,
      slots: {
        customRender: 'order',
      },
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      slots: {
        customRender: 'title',
      },
    },
    {
      title: 'Ссылка',
      dataIndex: 'slug',
      key: 'slug',
      sorter: true,
      slots: {
        customRender: 'slug',
      },
    },
    {
      title: 'Иконка',
      dataIndex: 'logo',
      key: 'logo',
      sorter: true,
      slots: {
        customRender: 'logo',
      },
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      sorter: true,
      key: 'added_on',
      slots: { customRender: 'date' },
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      sorter: true,
      key: 'updated_on',
      slots: { customRender: 'updated' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showEditModal = (id) => {
    docId.value = id
    editModal.value = true;
  },
  formattedDatetime = (date) => {
    date = date.replace(/ /g,"T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление записи',
      content: `Вы действительно хотите удалить запись?`,
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        return apiClient.delete(`/integrations/delete?id=${id}`).then(response => {
          if (response) {
            message.success('Запись успешно удалена')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  updateColumn = (id, title, column, value) => {
    let data = {};
    data['title'] = title;
    data[column] = value;
    apiClient.put(`/integrations/update?id=${id}`, data).then(response => {
      if (response) {
        message.success('Запись успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  getIcon = (logo) => {
    return `<i class="fe fe-${logo}"></i>`
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/integrations/index', {
      params,
    }).then(({data}) => {
      dataSource.value = data.data.items
      if (data.data.items.length > 0) {
        treeData.value = []
        data.data.items.forEach((item) => {
          treeData.value.push({
            title: item.title,
            logo: item.logo,
            i: item.id
          })
        })
      }
      total.value = data.data.total
      loading.value = false
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };
onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'integrations',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped src="./index.css"></style>
